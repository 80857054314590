import './NotFound.css';
import {
  useLocation
} from "react-router-dom";

import React from 'react'

function NotFound() {
  let location = useLocation();
    return (
        <div className="NotFound">
            <h1>Missing or non-existent resource</h1>
              <h3>
                No match for <code>{location.pathname}</code>
              </h3>
            <p>We're so sorry.  That url no longer seems to be valid.  If you believe this is an error, please reach out to <a href="mailto:support@dataskeptic.com">support@dataskeptic.com</a></p>
        </div>
    );
}

export default NotFound;
