import 'bootstrap/dist/css/bootstrap.min.css';
import './Home.css';
import wait from './wait.gif'
import Episode from './homepage/Episode'
import React, { useState, useEffect } from "react";
import { Container, Row, Col } from 'reactstrap';
import { Helmet } from 'react-helmet'

function Home(props) {
  const podcast = props.podcast
  const season = props.season
  const [logo, setLogo] = useState(wait)
  const [title, setTitle] = useState("")
  const [feedUrl, setFeedUrl] = useState("")

  const rss_image = "https://s3.amazonaws.com/6wingsfriedhard.com/images/rss.png"
  // const url = 'https://beta.dataskeptic.com/api/kyle@dataskeptic.com/api/6wfh';
  const api = 'https://por8ht9sv9.execute-api.us-east-1.amazonaws.com/api';
  const url = `${api}/podcast/${podcast}/season/${season}`;

  const [episodesData, setEpisodesData] = useState({ episodes: [] });
  const getDataFromApi = async () => {
      const response2 = await fetch(url, { headers: { authorization: 'c41296085cad52138c30ca01e8bb8f97' } });
      const bresp = await response2.json();
      setTitle(bresp['title'])
      setLogo(bresp['logo'])
      setFeedUrl(bresp['feed_url'])
      if (bresp.items !== undefined) {
        setEpisodesData({
          episodes: bresp.items
        });        
      } else {
        console.log("ERROR: got null episodes back")
      }
  };
  useEffect(() => {
    getDataFromApi();
  }, []);
  const episodes = episodesData['episodes']
  const episodeContainer = []
  for (const episode of episodes) {
    // TODO: update site - it should not show future episodes
    const div = <Episode episode={episode} />
    episodeContainer.push(div)
  }
  if (episodes.length === 0) {
    episodeContainer.push(<div key="no"><h1>No episodes found.</h1></div>)
  }
  return (
    <div className="Home">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Container>
        <Row>
          <Col xs="12" sm="4">
            <div className="left">
              <img src={logo} className="App-logo" alt="logo" />
              <h1>6 Wings Fried Hard</h1>
              <a href={feedUrl}><img className="rss-icon" src={rss_image} /></a>
            </div>
          </Col>
          <Col xs="12" sm="8" className="z">
            {episodeContainer}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Home;
