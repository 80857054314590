import { Container, Row, Col } from 'reactstrap';
import Player from '../player/Player'
import './Episode.css';


function Episode(props) {
  const episode = props.episode
  console.log({episode})
  const cover_art_url = episode.cover_art_url
  const blog_url = episode.blog_url
  var epLink = blog_url
  const rd = (new Date(episode['release_date'])).toLocaleDateString()
  if (window.location.host !== "6wingsfriedhard.com") {
    const host = window.location.protocol + '//' + window.location.host
    const i = blog_url.indexOf('/', 8)
    epLink = host + blog_url.substring(i)
  }
  const guests = []
  const cover_alt = "Cover art for " + episode.title
  // <br/>
  // <Player title={episode.title} url={episode.enclosure_url} guests={guests} />
  return (
    <div className="HomeEpisode">
      <Container>
        <Row>
          <Col xs="12" sm="3">
            <img className="episode-art" src={cover_art_url} alt={cover_alt}/>
          </Col>
          <Col xs="12" sm="9">
            <div className="right"><span className="episode-title"><a href={epLink}>{episode.title}</a></span>
            </div>
            <div className="home-date">{rd}</div>
            <div className="content" dangerouslySetInnerHTML={{ __html: episode.description }} />
            <div>
             </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Episode;
