import './App.css';
import Episode from './Episode'
import Home from './Home'
import 'bootstrap/dist/css/bootstrap.css';
import NotFound from './NotFound';
import ReactGA from 'react-ga';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";


function App(props) {
  console.log({props})
  // var home_logo = "https://s3.amazonaws.com/dataskeptic.com/ds_sm.jpg"
  ReactGA.pageview(window.location.pathname + window.location.search);
  const podcast = "6wfh"
  const season = "one"
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route exact path="/">
              <Home podcast={podcast} season={season} />
          </Route>
          <Route path="/episodes/:slug">
              <Episode podcast={podcast} season={season} />
          </Route>
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}



export default App;

