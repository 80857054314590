import './Episode.css';
import React, { useState, useEffect } from "react";
import Player from './player/Player'
import { Container, Row, Col } from 'reactstrap';
import { useParams } from 'react-router-dom'

function Episode(props) {
    const podcast = props.podcast
    const { slug } = useParams()
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [enclosureUrl, setEnclosureUrl] = useState("")
    const [itunesDuration, setItunesDuration] = useState("")
    const [pubDate, setPubDate] = useState("")
    const [coverArt, setCoverArt] = useState("")
    const [duration, setDuration] = useState("")
    const [albumId, setAlbumId] = useState("")

    const api = 'https://por8ht9sv9.execute-api.us-east-1.amazonaws.com/api';
    const url = `${api}/podcast/${podcast}/episode/${slug}`;
    // const url = 'https://beta.dataskeptic.com/api/kyle@dataskeptic.com/api/6wfh/'
    const getDataFromApi2 = async () => {
        const response2 = await fetch(url);
        const bresp = await response2.json();
        console.log({bresp})
        setTitle(bresp['title'])
        setDescription(bresp['description'])
        setEnclosureUrl(bresp['enclosure_url'])
        setItunesDuration(bresp['itunes_duration'])
        const rd = (new Date(bresp['release_date'])).toLocaleDateString()
        setPubDate(rd)
        setCoverArt(bresp['cover_art_url'])
        setDuration(bresp['mp3_duration'])
        setAlbumId(bresp['spotify_album_id'])
    };
    useEffect(() => {
      getDataFromApi2();
    }, [getDataFromApi2]);
    const guests = []
    const spotify_src = `https://open.spotify.com/embed/album/${albumId}`
    console.log({albumId})
    return (
        <div className="Episode">
          <br/>
          <center><a href="/">Home</a></center>
          <br/>
          <Container>
            <Row>
              <Col xs="12" sm="4">
                <div className="EpisodeImageContainer">
                  <img className="EpisodeImage" src={coverArt} />
                </div>
              </Col>
              <Col xs="12" sm="8">
                <h1>{title}</h1>
                <p className="home-date">{pubDate}</p>
                <p>{description}</p>
                <Player title={title} url={enclosureUrl} guests={guests} />
                <br/><br/>
                {itunesDuration}
                {duration}
                {albumId && <b className="bbb">Listen along to our album of the week:</b>}
                <br/><br/>
                {albumId && <iframe className="spotify_iframe" src={spotify_src} frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>}
              </Col>
            </Row>
          </Container>
        </div>
    );
}

export default Episode;
