import axios from 'axios';
import './Player.css';
import React, { useState } from 'react'
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';


// TODO: guest from timeline
// TODO: show stats
// TODO: don't track button

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 || 0, v = c === 'x' ? r : (r && 0x3 | 0x8);
    return v.toString(16);
  });
}

function get_user_id() {
  const var_name = 'data-skeptic-player-anon-id'
  var user_id = localStorage.getItem(var_name);
  if (!user_id) {
    user_id = uuidv4()
    localStorage.setItem(var_name, user_id)
  }
  return user_id
}

function tick(eventQueue, setEventQueue, media_url, pos) {
  var last_ping_time = localStorage.getItem("last_ping_time")
  if (!last_ping_time) {
    last_ping_time = new Date().getTime()
    localStorage.setItem("last_ping_time", last_ping_time)
  } else {
    last_ping_time = parseInt(last_ping_time)
  }
  if (last_ping_time + 5000 < new Date().getTime() && eventQueue.length === 0) {
    const user_id = get_user_id()
    recordAnalytics('ping', eventQueue, setEventQueue, media_url, user_id, { pos })
    localStorage.setItem("last_ping_time", new Date().getTime())
  }
  const url = "https://por8ht9sv9.execute-api.us-east-1.amazonaws.com/api/player/event"
  if (eventQueue.length > 0) {
    var n = eventQueue.length
    if (n > 20) {
      n = 20
      console.log("buffering player events...")
    }
    const data = []
    while (n > 0) {
      const item = eventQueue.pop()
      data.push(item)
      n = n - 1
    }
    axios.post(url, {events: data}).then((r) => {
      if (!r.data.success) {
        console.log({api_error: r.data})
      }
    }).catch((err) => {
      console.log({err})
    })
  }
}

function recordAnalytics(eventType, eventQueue, setEventQueue, url, user_id, extra={}) {
  const event = {
    type: eventType,
    ts: new Date().getTime(),
    url,
    user_id,
    ...extra
  }
  eventQueue.push(event)
  setEventQueue(eventQueue)
}

function Player(props) {
  const [eventQueue, setEventQueue] = useState([])
  const url = props.url
  const mode = props.mode
  const user_id = get_user_id()
  // React.useEffect(() => {
  //   var timerID = setInterval( () => tick(eventQueue, setEventQueue, url), 1000 );
  //   return function cleanup() {
  //       clearInterval(timerID);
  //     };
  // });
  if (url === undefined) {
    return <div></div>
  }
  var layout = 'horizontal-reverse'
  var showSkipControls = false
  var showJumpControls = true
  if (mode === 'mini') {
    layout = 'stacked'
    showJumpControls = false
  }
  const error_msg = "TODO: retrieve value from AudioPlayer"
  const vol = "TODO: ?"
  return (
    <div className="player">
      <AudioPlayer
        src={url}
        autoPlayAfterSrcChange={false}
        showDownloadProgress={true}
        showFilledProgress={true}
        loop={false}
        customAdditionalControls={[]}
        showJumpControls={showJumpControls}
        showSkipControls={showSkipControls}
        layout={layout}
        onListened={e => console.log({e})}
        onPlay={e => recordAnalytics("onPlay", eventQueue, setEventQueue, url, user_id, {})}
        onPause={e => recordAnalytics("onPause", eventQueue, setEventQueue, url, user_id, {pos: e.timeStamp})}
        onEnded={e => recordAnalytics("onEnded", eventQueue, setEventQueue, url, user_id, {})}
        onSeeking={e => recordAnalytics("onSeeking", eventQueue, setEventQueue, url, user_id, {pos: e.timeStamp})}
        onSeeked={e => recordAnalytics("onSeeked", eventQueue, setEventQueue, url, user_id, {pos: e.timeStamp})}
        onAbort={e => recordAnalytics("onAbort", eventQueue, setEventQueue, url, user_id, {pos: e.timeStamp})}
        onCanPlay={e => recordAnalytics("onCanPlay", eventQueue, setEventQueue, url, user_id, {})}
        onCanPlayThrough={e => recordAnalytics("onCanPlayThrough", eventQueue, setEventQueue, url, user_id, {})}
        onEmptied={e => recordAnalytics("onEmptied", eventQueue, setEventQueue, url, user_id, {})}
        onError={e => recordAnalytics("onError", eventQueue, setEventQueue, url, user_id, {error_msg})}
        onLoadStart={e => recordAnalytics("onLoadStart", eventQueue, setEventQueue, url, user_id, {})}
        onLoadedMetaData={e => recordAnalytics("onLoadedMetaData", eventQueue, setEventQueue, url, user_id, {})}
        onLoadedData={e => recordAnalytics("onLoadedData", eventQueue, setEventQueue, url, user_id, {})}
        onPlaying={e => recordAnalytics("onPlaying", eventQueue, setEventQueue, url, user_id, {pos: e.timeStamp})}
        onSuspend={e => recordAnalytics("onSuspend", eventQueue, setEventQueue, url, user_id, {pos: e.timeStamp})}
        onWaiting={e => recordAnalytics("onWaiting", eventQueue, setEventQueue, url, user_id, {})}
        onVolumeChange={e => recordAnalytics("onVolumeChange", eventQueue, setEventQueue, url, user_id, {vol})}
        onListen={e => tick(eventQueue, setEventQueue, url, e.timeStamp)}
      />
    </div>
  );
}

export default Player;
